// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-birthcontrol-js": () => import("./../../../src/pages/projects/birthcontrol.js" /* webpackChunkName: "component---src-pages-projects-birthcontrol-js" */),
  "component---src-pages-projects-bruce-js": () => import("./../../../src/pages/projects/bruce.js" /* webpackChunkName: "component---src-pages-projects-bruce-js" */),
  "component---src-pages-projects-documents-js": () => import("./../../../src/pages/projects/documents.js" /* webpackChunkName: "component---src-pages-projects-documents-js" */),
  "component---src-pages-projects-hurricanes-js": () => import("./../../../src/pages/projects/hurricanes.js" /* webpackChunkName: "component---src-pages-projects-hurricanes-js" */),
  "component---src-pages-projects-midterms-js": () => import("./../../../src/pages/projects/midterms.js" /* webpackChunkName: "component---src-pages-projects-midterms-js" */),
  "component---src-pages-projects-survivor-js": () => import("./../../../src/pages/projects/survivor.js" /* webpackChunkName: "component---src-pages-projects-survivor-js" */)
}

